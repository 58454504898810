<template>
  <div>
    <TitleWrapper :filter-option="false" title="Notifications" :display-breadcrumb="true" />
    <div
      :class="$style.minHieght80"
      class="main-content bg-primary-white rounded-t-lg mt-5 p-5 border-border-color-lighter border"
    >
      <!-- Staff Login Enable Disable Notification -->
      <div class="flex justify-between">
        <p class="text-xl flex w-full md:mt-4 pr-3 mb-3 font-bold">
          Reminder for staff login enable/disable
        </p>
      </div>
      <div v-if="!isLoadingStaffEnableDisable" class="mt-5 rounded-lg border border-primary-grey">
        <div v-if="false">
          <NoRecordFound />
        </div>
        <NotificationTableType1 v-else :notification-list="staffEnableDisableList" />
      </div>
      <div v-else class="mt-16 mb-28">
        <Loader :content="true" />
      </div>
      <Pagination
        v-if="true"
        v-show="!isLoadingStaffEnableDisable"
        :record-limit="filteredRecordLimit"
        :max-range="paginationCounts(staffEnableDisableCount, filteredRecordLimit)"
        @filterRecord="filterRecord"
      />

      <!-- De-activate user Notification -->
      <div class="flex justify-between">
        <p class="text-xl flex w-full md:mt-4 pr-3 mb-3 font-bold">Reminder for de-activate user</p>
      </div>
      <div v-if="!isLoadingDeactivateUser" class="mt-5 rounded-lg border border-primary-grey">
        <div v-if="false">
          <NoRecordFound />
        </div>

        <NotificationTableType2 v-else :notification-list="deActivateUserList" />
      </div>
      <div v-else class="mt-16 mb-28">
        <Loader :content="true" />
      </div>
      <Pagination
        v-if="true"
        v-show="!isLoadingDeactivateUser"
        :record-limit="filteredRecordLimit"
        :max-range="paginationCounts(deActivateUserCount, filteredRecordLimit)"
        @filterRecord="filterRecord"
      />

      <!-- New Staff Creation Notification -->
      <div class="flex justify-between">
        <p class="text-xl flex w-full md:mt-4 pr-3 mb-3 font-bold">Reminder for staff creation</p>
      </div>
      <div v-if="!isLoadingstaffCreation" class="mt-5 rounded-lg border border-primary-grey">
        <div v-if="false">
          <NoRecordFound />
        </div>
        <NotificationTableType2 v-else :notification-list="staffCreationList" />
      </div>
      <div v-else class="mt-16 mb-28">
        <Loader :content="true" />
      </div>
      <Pagination
        v-if="true"
        v-show="!isLoadingstaffCreation"
        :record-limit="filteredRecordLimit"
        :max-range="paginationCounts(staffCreationCount, filteredRecordLimit)"
        @filterRecord="filterRecord"
      />
    </div>
    <AddExamTemplate
      v-if="showStaffEnableDisableModal"
      :show-model="showStaffEnableDisableModal"
      label="staff login enable/disable"
      @handleClick="toggleShowStaffEnableDisableModal"
      @update="addStaffEnableDisable"
    />
    <AddExamTemplateType2
      v-if="showDeActivateUserModal"
      label="de-activate user"
      :show-model="showDeActivateUserModal"
      @handleClick="toogleShowDeActivateUserModal"
      @update="addDeActivateUser"
    />
    <AddExamTemplateType2
      v-if="showUserCreationModal"
      label="user creation"
      :show-model="showUserCreationModal"
      @handleClick="toggleShowUserCreationModal"
      @update="addUserCreation"
    />
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import AddExamTemplate from '@/src/components/Settings/notifications/AddEditNotificationAlertTemplate1.vue'
import AddExamTemplateType2 from '@/src/components/Settings/notifications/CreateUnScheduleNotification.vue'
import NotificationTableType1 from '@/src/components/Settings/notifications/NotificationTableType1.vue'
import NotificationTableType2 from '@/src/components/Settings/notifications/NotificationTableType2.vue'
/* Utils or mixins */
import GeneralUtil from '@src/mixins/general-mixins.js'
import { mapActions } from 'vuex'
import NoRecordFound from '@components/BaseComponent/NoRecordFound.vue'

export default {
  components: {
    TitleWrapper,
    Pagination,
    Loader,
    AddExamTemplate,
    AddExamTemplateType2,
    NotificationTableType1,
    NotificationTableType2,
    NoRecordFound,
  },

  mixins: [GeneralUtil],
  page: {
    title: 'Notification | Staff',
  },
  data() {
    return {
      notifications: 'notifications',
      isLoadingStaffEnableDisable: false,
      isLoadingDeactivateUser: false,
      isLoadingstaffCreation: false,
      staffEnableDisableCount: 20,
      deActivateUserCount: 20,
      staffCreationCount: 20,
      filteredRecordLimit: 5,
      showStaffEnableDisableModal: false,
      showDeActivateUserModal: false,
      showUserCreationModal: false,
      staffEnableDisableList: [
        {
          role: {
            id: '0',
            name: 'Student',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          days: '2',
          days_before: {
            id: '0',
            name: 'Before',
          },
          due_date: '2:00 pm',
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },
        {
          role: {
            id: '0',
            name: 'Student',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          days: '2',
          days_before: {
            id: '0',
            name: 'Before',
          },
          due_date: '2:00 pm',
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },
      ],
      deActivateUserList: [
        {
          role: {
            id: '0',
            name: 'Student',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },

        {
          role: {
            id: '0',
            name: 'Student',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },
      ],
      staffCreationList: [
        {
          role: {
            id: '0',
            name: 'Student',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },
        {
          role: {
            id: '0',
            name: 'Student',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },
      ],
    }
  },
  created() {
    this.setRightBar()
  },
  methods: {
    setRightBar() {
      const rightBarContent = {
        header: {
          title: 'Notifications',
          buttons: [
            {
              title: 'Create New Notification',
              classes: ['primary-button-right'],
              options: [
                {
                  title: 'STAFF_ENABLE_DISABLE',
                  action: {
                    func: () => {
                      this.toggleShowStaffEnableDisableModal()
                    },
                  },
                  payload: {},
                },
                {
                  title: 'USER_ACTIVATE',
                  action: {
                    func: () => {
                      this.toogleShowDeActivateUserModal()
                    },
                  },
                  payload: {},
                },
                {
                  title: 'STAFF_CREATION',
                  action: {
                    func: () => {
                      this.toggleShowUserCreationModal()
                    },
                  },
                  payload: {},
                },
              ],
            },
          ],
        },
      }
      this.setRightbarContent(rightBarContent)
    },

    addStaffEnableDisable(e) {
      this.staffEnableDisableList.push(e)
    },

    addDeActivateUser(e) {
      this.deActivateUserList.push(e)
    },

    addUserCreation(e) {
      this.staffCreationList.push(e)
    },

    filterRecord() {},

    toggleShowStaffEnableDisableModal(eventName) {
      this.showStaffEnableDisableModal = !this.showStaffEnableDisableModal
    },

    toogleShowDeActivateUserModal(eventName) {
      this.showDeActivateUserModal = !this.showDeActivateUserModal
    },

    toggleShowUserCreationModal(eventName) {
      this.showUserCreationModal = !this.showUserCreationModal
    },
    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>

<style lang="scss" module>
.minHieght80 {
  min-height: 80vh;
}
</style>
