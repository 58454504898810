<template>
  <div class="overflow-x-auto">
    <table class="w-full table-auto border-b mb-0.5 border-primary-grey whitespace-nowrap">
      <tbody>
        <tr
          v-for="(notify, index) in notificationList"
          :key="index"
          class="border-t border-primary-grey text-left"
        >
          <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-4">
            <span class="font-roboto font-normal text-sm cursor-pointer">Send a reminder to</span>
          </td>
          <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-4">
            <span v-if="notify.user" class="font-roboto font-normal text-sm cursor-pointer">
              <span class="text-primary-green">{{ notify.user.name }}</span>
            </span>
            <span v-else>{{ GENERAL_CONSTANTS.NOT_APPLICABLE }}</span>
          </td>
          <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-4">
            <span v-if="notify.role" class="font-roboto font-normal text-sm cursor-pointer">
              <span class="text-primary-green">{{ notify.role.name }}</span>
            </span>
            <span v-else>{{ GENERAL_CONSTANTS.NOT_APPLICABLE }}</span>
          </td>
          <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-4">
            <span v-if="notify.days" class="font-roboto font-normal text-sm cursor-pointer">
              <span class="text-primary-green">{{ notify.days }}</span>
              Days
            </span>
            <span v-else>{{ GENERAL_CONSTANTS.NOT_APPLICABLE }}</span>
          </td>
          <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-4">
            <span v-if="notify.days_before" class="font-roboto font-normal text-sm cursor-pointer">
              <span class="text-primary-green">{{ notify.days_before.name }}</span>
            </span>
            <span v-else>{{ GENERAL_CONSTANTS.NOT_APPLICABLE }}</span>
          </td>
          <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-4">
            <span v-if="notify.due_date" class="font-roboto font-normal text-sm cursor-pointer">
              at
              <span class="text-primary-green">{{ notify.due_date }}</span>
            </span>
            <span v-else>{{ GENERAL_CONSTANTS.NOT_APPLICABLE }}</span>
          </td>
          <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-4">
            <span v-if="notify.mediums" class="font-roboto font-normal text-sm cursor-pointer flex">
              <span v-for="(medium, i) in notify.mediums" :key="i" class="text-primary-green flex">
                {{ medium.name }}
                <Icon
                  v-if="i + 1 !== notify.mediums.length"
                  class="icon opacity-50 lg:opacity-100 cursor-pointer mt-0.5 mx-2"
                  color="primary-green"
                  icon="arrowRight"
                  height="15"
                  width="15"
                />
              </span>
            </span>
            <span v-else>{{ GENERAL_CONSTANTS.NOT_APPLICABLE }}</span>
          </td>
          <td class="border-primary-grey w-10 px-5">
            <TableAction
              :idx="index"
              :current-user="notify"
              :action-list="actionList"
              :right="50"
              :rtl-right="-245"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import TableAction from '@components/TableAction.vue'
import Icon from '@components/icons/icon.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'

export default {
  name: 'NotificationTaleType1',
  components: {
    TableAction,
    Icon,
  },
  props: {
    notificationList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      GENERAL_CONSTANTS,
      actionList: [{ name: 'Edit' }, { name: 'Delete' }],
    }
  },
}
</script>
